<template>
  <ScheduleUserTemplate
    :headerProps="headerProps"
    :mediaInfo="mediaInfo"
    :content="content"
    :params="$route.query"
    :event="event"
    @onSubmit="onSubmit"
  />
</template>

<script>
import ScheduleUserTemplate from "@/components/templates/ScheduleUser/ScheduleUser";
import { headerProps } from "@/components/constants";
import heroImg from "@/assets/images/sample-hero.jpg";
import { Settings } from "../../settings";

export default {
  components: {
    ScheduleUserTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
      content: {
        title: "User Information",
      },
      event: null,
    };
  },
  async created() {
    const events = await this.actions.event.getEvents({where: {id: this.$route.query?.eventId}, getValues: true});
    if (events.length === 0) {
      this.$router.go(-1);
      return false;
    }
    this.event = events[0]
  },
  methods: {
    async onSubmit(form) {
      const time = this.$route.query?.time;
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time?.slice(-2);
      if(AMPM == "PM" && hours<12) hours = hours+12;
      if(AMPM == "AM" && hours==12) hours = hours-12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if(hours<10) sHours = "0" + sHours;
      if(minutes<10) sMinutes = "0" + sMinutes;
      const startTime = new Date(this.$route.query?.date + 'T' + sHours + ":" + sMinutes + ':00');
      const endTime = new Date(startTime.getTime() + parseInt(this.event.duration?.split('M')?.[1]) * 60 * 1000) ;
      const createAppUser = await this.actions.createAppUser({
        email: form.email,
        sendEmailVerification: false,
      });
      if (createAppUser?.user?.id) {
        await this.actions.user.updateUser({
          where: {id: createAppUser?.user?.id},
          data: {
            fullName: form.name,
            phones: {create: [{number: form.phone.replace(/[^A-Z0-9]/gi, ""),}]},
          }
        })
        await this.actions.calendar.saveCalendar({
          data: {
            owner: {connect: {id: this.event.creator?.id}},
            attendees: {
              connect: [{id: createAppUser?.user?.id}]
            },
            startTime,
            endTime,
            url: `https://${Settings.mainDomain}/#/calendar/${createAppUser.user?.id}/${this.event?.id}`,
            description: form.message
          }
        })
        this.$router.push('/payment')
      }
    }
  },
};
</script>

<style scoped>
</style>
