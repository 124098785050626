<template>
    <HeaderSection v-bind="headerProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>

    <div class="content-wrap">
		<div class="entry entry-page">
			<h2 class="entry-title">{{content.title}}</h2>
			<div class="entry-content">
				<div class="grid grid-cols-2 divide-x divide-gray-600">
                    <div>
                        <div class="flex items-center space-x-2">
                            <Avatar :src="event?.creator?.avatar" size="7" isCircle image-type="placeholder-icon" />
                            <Text size="" custom-class="leading-8" color="gray-500" :content="`${event?.creator?.firstName} ${event?.creator?.lastName}`"/>
                        </div>
                        <Text size="2xl" weight="semibold" color="gray-700" :content="event?.name" customClass="mt-3"/>
                        
                        <div class="flex items-center space-x-2 mt-2">
                            <Icon name="ClockIcon" isOutline size="6" color="gray-500"/>
                            <Text size="lg" color="gray-500" :content="`${event?.duration?.split('M')?.[1]} Minutes`"/>
                        </div>
                        <div class="flex items-center space-x-2">
                            <Icon name="CalendarIcon" isOutline size="6" color="gray-500"/>
                            <Text size="lg" color="gray-500" :content="getDate"/>
                        </div>
                    </div>
                    <div class="px-8 space-y-4">
                        <Inputs name="name" label="Your Name *" placeholder="" type="text" v-model:value="form.name" />
                        <Inputs name="email" label="Email *" placeholder="" type="email" v-model:value="form.email" />
                        <Inputs name="phone" label="Phone *" placeholder="" type="text" inputMask="(###) ###-####" v-model:value="form.phone" />
                        <Inputs name="note" label="Additional Notes" placeholder="" type="text" v-model:value="form.message" />
                    </div>
                </div>
                <Button @click="() => $emit('onSubmit', form)" class="button-filled button-color submit-btn mt-5" size="size" variant="abc">CONFIRM</Button>
			</div>
		</div>
		<CopyRight v-bind="footerProps"/>
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import moment from 'moment';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
import Text from '../../atoms/Text/Text.vue'
import Avatar from '../../atoms/Avatar/Avatar.vue'
import Icon from '../../atoms/Icons/Icons.vue'
import Inputs from '../../molecules/Inputs/Component/Component.vue';

/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, Carousel, CopyRight, Text, Avatar, Icon, Inputs
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
        content: {
            type: Object,
            default: () => null
        },
        event: {
            type: Object,
            default: () => {}
        },
        params: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            footerProps,
            moment,
            form: {
                name: null,
                email: null,
                phone: null,
                message: null,
            }
        }
    },
    computed: {
        getDate() {
            return this.params?.time + ', ' + moment(this.params.date).add('hours', 10).format('dddd, MMMM DD, YYYY')
        }
    }
}
</script>

<style>

</style>